<!-- 
	This is the DataTables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>

	<div>

		<!-- Searchable Datatable card -->
		<a-card :bordered="false" class="header-solid mb-24 px-25" :bodyStyle="{padding: 0, paddingTop: '16px'}">
			<a-row class="mx-25 pt-10">
				<a-col :span="12">
					<h5 class="font-semibold">List of Teachers</h5>
				</a-col>
				<a-col :span="12" class="text-right">
					<a-button type="primary" href="/staff-management/teachers/add-new" class="px-20 mb-10">
						<a-icon type="plus" theme="outlined" class="text-white mr-5" />Add New 
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-0 pt-0"/>
				</a-col>
			</a-row>
			

			<div class="mx-25 mt-15 pt-10">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" :md="12">
						<a-select v-model="pagination.perPage" :options="pagination.perPageOptions" style="width: 70px"/>
						<label for="" class="ml-10">entries per page</label>
					</a-col>
					<a-col :span="24" :md="12" class="text-right">
    					<a-input-search  
							placeholder="search name..." 
							style="max-width: 250px;" 
							v-model="pagination.search"  
      						@search="onSearchItem">
							<template #suffix v-if="pagination.search != null && pagination.search.length > 0">
								<a-icon type="close" theme="outlined" class="text-danger mr-10"  @click="onClearSearchItem" />
							</template>
						</a-input-search>
					</a-col>
				</a-row>
			</div>
			
			<div class="mx-25 pb-25">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" :md="24">
						<a-table class="mt-20"  
							size="middle"
							:bordered="false"
							:columns="columns" 
							:data-source="teachers" 
							:rowKey="record => record.uuid"
							:loading="pagination.loading" 
							:pagination="false">

								<template slot="s_no" slot-scope="s_no, record, index">
									{{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
								</template>

								<template slot="fullname" slot-scope="fullname, record">
									{{ record.firstName }} {{ record.middleName != null ? `${record.middleName[0].toUpperCase()}.` : '' }} {{ record.lastName }}
								</template>

								<template slot="contacts" slot-scope="contacts, record">
									Phone: {{ record.phone }} <br/>
									Email: {{ record.email }}
								</template>

								<template slot="dateOfAppointment" slot-scope="dateOfAppointment">
									{{ dateOfAppointment != null ? $Moment(dateOfAppointment).format("DD MMM YYYY") : '-' }}
								</template>

								<template slot="isActive" slot-scope="isActive">
									<a-badge  
										style="font-size: 10px !important" 
										:count="isActive ? 'Active' : 'Inactive'" 
										:number-style="isActive ? { backgroundColor: '#52C41A', color: '#FFFFFF'} : { backgroundColor: '#D9D9D9', color: '#444', boxShadow: '0 0 0 1px #d9d9d9 inset'}"/>
								</template>
								
								<template slot="createdAt" slot-scope="createdAt">
									{{ $Moment(createdAt).format("DD MMM YYYY") }}
								</template>

								<template slot="action" slot-scope="action, record">
									<a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #188C54;" :href="`/staff-management/teachers/view/${record.uuid}`">
										<a-icon type="eye" /> View
									</a-button>
								</template>
							
						</a-table>
					</a-col>
					<a-col :span="12" class="mt-25 text-muted">
						<p class="category">Showing {{from + 1}} to {{to}} of {{pagination.total}} entries</p>
					</a-col>
					<a-col :span="12" class="text-right mt-25">
						<a-pagination 
							v-model="pagination.currentPage" 
							:pageSize="pagination.perPage"  
							:total="pagination.total" 
							@change="onCurrentPageChange"/>
					</a-col>
				</a-row>
			</div>
		</a-card>

	</div>

</template>

<script>

	// Second table's list of columns
	const columns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'FULL NAME',
			dataIndex: 'fullname',
			// sorter: (a, b) => a.firstName.length - b.firstName.length,
			// sortDirections: ['descend', 'ascend'],
			scopedSlots: { customRender: 'fullname' },
		},
		{
			title: 'IDENTIFICATION NO.',
			dataIndex: 'employeeNo',
			scopedSlots: { customRender: 'employeeNo' },
		},
		{
			title: 'GENDER',
			dataIndex: 'gender',
			scopedSlots: { customRender: 'gender' },
		},
		{
			title: 'CONTACTS',
			dataIndex: 'contacts',
			scopedSlots: { customRender: 'contacts' },
		},
		{
			title: 'DATE OF APPOINTMENT',
			dataIndex: 'dateOfAppointment',
			scopedSlots: { customRender: 'dateOfAppointment' },
		},
		{
			title: 'STATUS',
			dataIndex: 'isActive',
			scopedSlots: { customRender: 'isActive' },
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			align: "center"
		},
	];

	
	export default {
		components: {
		},
		data() {
			return {

				pagination: {
					search: '',
					loading: false,
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
                    total: 10
                },

				columns,
				message: '',

				teachers: [],
			

			}
		},
        computed: {
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
        },
		watch: {
            pagination: {
                handler() {
                    this.getTeachers();
                },
            },
            deep: true,
        },
		created() {
			this.getTeachers();
		},
		methods: {

			onSearchItem() {
				if(this.pagination.search != null && this.pagination.search.length > 0) {
					this.getTeachers()
				}else{
					let message = "Please enter something to search"
					let type = "danger"

					this.notify(type, message)
				}
			},

			onClearSearchItem() {
				this.pagination.search = '';

				this.getTeachers();
			},

			onCurrentPageChange(value) {
				this.pagination.currentPage = value;

				this.getTeachers();
			},

			onPerPageChange(value) {
                this.pagination.perPage = value;

                this.getTeachers();
            },

			getTeachers() {
                this.pagination.loading = true;

                const { currentPage, perPage, search } = this.pagination;

                let url = `${this.$BACKEND_URL}/teachers/?search=${search}&page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.teachers = response.data.data;
                            this.pagination.totalPages = response.data.totalPages;
                            this.pagination.total = response.data.total;
                            this.pagination.currentPage = response.data. currentPage;
                        }else{
                            let message = "There is no data available"
							let type = "success"

                    		this.notify(type, message)
                        }
                    }
                    this.pagination.loading = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                    this.pagination.loading = false;
                });
            },


			notify(type, message) {
				this.$notification.open({
					class: 'ant-notification-' + type,
					message: message,
					duration: 3.0,
					style: {fontSize: "10px !important"}
				});
			},

		},

		
	}

</script>

<style lang="scss">

	#txtBtn, #txtBtn:hover {
		border: none;
	}
</style>